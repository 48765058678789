import fromEntries from 'object.fromentries'
import { NUMBER_DIMENSION_REGEX } from '~/constants/regexes'

export function jsonToFormData(inJSON, inFormData, parentKey) {
  var formData = inFormData || new FormData()
  for (var key in inJSON) {
    var constructedKey = key
    if (parentKey) {
      constructedKey = parentKey + '.' + key
    }
    var value = inJSON[key]
    if (value && value.constructor === {}.constructor) {
      jsonToFormData(value, formData, constructedKey)
    } else {
      formData.append(constructedKey, value)
    }
  }
  return formData?.fd || formData
}

export function objectMap(obj, fn) {
  if (!Object.fromEntries) fromEntries.shim()
  return Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )
}

export function groupBy(arr, key) {
  return arr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

// TODO REFACTOR: ...props
export function pick(...props) {
  return o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {})
}

export function sortBy(arr, keys) {
  const newArr = JSON.parse(JSON.stringify(arr))
  return newArr.sort(fieldSorter(keys))
}

export function fieldSorter(fields) {
  var dir = [], i, l = fields.length
  fields = fields.map((o, i) => {
    if (o[0] === '-') {
      dir[i] = -1
      o = o.substring(1)
    } else {
      dir[i] = 1
    }
    return o
  })

  return function (a, b) {
    for (i = 0; i < l; i++) {
      var key = fields[i]

      let varA = a[key]
      if (typeof a[key] === 'string') {
        if (NUMBER_DIMENSION_REGEX.test(a[key])) varA = Number(a[key].match(/\d+/g)[0])
        else varA = a[key].toUpperCase()
      }
      let varB = b[key]
      if (typeof b[key] === 'string') {
        if (NUMBER_DIMENSION_REGEX.test(b[key])) varB = Number(b[key].match(/\d+/g)[0])
        else varB = b[key].toUpperCase()
      }

      if (varA > varB) return dir[i]
      if (varA < varB) return -(dir[i])
    }
    return 0
  }
}
