import { jsonToFormData } from '~/helpers/data-processor'

const RasylvaApi = {
  install(Vue) {
    const isValidSoftwareToken = softwareToken => {
      softwareToken && softwareToken.status === 'active' && new Date(softwareToken.expired_at) > new Date()
    }
    const fetcher = async (url, { method = 'GET', headers = {}, body } = {}) => {
      let jwt = null
      const softwareToken = JSON.parse(localStorage.getItem('inventum-app-token'))
      if (headers['Authorization-Type'] === 'software-token' && isValidSoftwareToken(softwareToken)) {
        jwt = softwareToken
      } else {
        jwt = JSON.parse(localStorage.getItem('rasylva-jwt'))
        headers['Authorization-Type'] = 'jwt-token'
      }
      if (!jwt) throw new Error('Unauthorized. Pelase re-login.')
      const options = {
        method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'credentials': 'same-origin',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `brearer ${jwt.token}`,
          ...headers,
        },
      }
      if (body) options.body = JSON.stringify(body)
      const response = await fetch(`/api${url}`, options)
      const json = await response.json()
      return json
    }
    const serialize = obj => {
      var str = []
      for (var p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      return str.join('&')
    }
    Vue.prototype.$rasylvaApi = {
      jwt: null,
      softwareToken: null,
      init() {
        if (!this.jwt) this.setJwt(JSON.parse(localStorage.getItem('rasylva-jwt')) || {})
      },
      setJwt(jwt) {
        this.jwt = jwt
        localStorage.setItem('rasylva-jwt', JSON.stringify(jwt))
      },
      getGoogleToken() {
        return this.jwt?.google || {}
      },
      getUser() {
        return this.jwt?.user || {}
      },
      isLoggedIn() {
        if (!this.softwareToken) this.softwareToken = JSON.parse(localStorage.getItem('inventum-app-token')) || null
        const isUserExist = !!this.jwt?.user?.id
        const isValidToken = !!this.jwt?.exp && new Date(this.jwt.exp) > new Date()
        return isUserExist && (isValidToken || isValidSoftwareToken(this.softwareToken))
      },
      login(requestPayload) {
        var formData = jsonToFormData(requestPayload)
        const options = {
          method: 'POST',
          body: new URLSearchParams(formData).toString(),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'credentials': 'same-origin',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
        return fetch('/auth/google_oauth2/callback', options)
          .then(response => {
            return response.json()
          })
          .then(response => {
            return { ...response, google: { ...requestPayload } }
          })
      },
      logout() {
        localStorage.removeItem('rasylva-jwt')
        localStorage.removeItem('inventum-app-token')
        if (navigator.credentials?.preventSilentAccess) {
          navigator.credentials.preventSilentAccess()
        }
        window.location.reload()
      },
      get(url, { headers = {}, body } = {}) {
        const options = { method: 'GET', headers }
        const params = body ? `?${serialize(body)}` : ''
        return fetcher(`${url}${params}`, options)
      },
      post(url, { headers = {}, body } = {}) {
        const options = { method: 'POST', headers, body }
        return fetcher(url, options)
      },
      patch(url, { headers = {}, body } = {}) {
        const options = { method: 'PATCH', headers, body }
        return fetcher(url, options)
      },
      put(url, { headers = {}, body } = {}) {
        const options = { method: 'PUT', headers, body }
        return fetcher(url, options)
      },
      delete(url, { headers = {}, body } = {}) {
        const options = { method: 'DELETE', headers, body }
        return fetcher(url, options)
      },
    }
  },
}

export default RasylvaApi
